import { fixResponseJSON, filterParams } from '../assets/helpers/funciones';
import { authInstance } from './axios';
let url = process.env.REACT_APP_API_URL;

export const rateService = {
  getDollarRate: async (userId, params) => {
    const response = await authInstance
      .get(`${url}/dolar/`)
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        error.data = [];
        return error;
      });
    return response;
  },

  getCurrencyRates: async params => {
    const response = await authInstance
      .get(
        `${url}/currencyRates/list?` + new URLSearchParams(filterParams(params))
      )
      .then(async response => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};
