import React, { useState, useCallback } from 'react';
import search from '../../../../images/search.svg';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';
import {
  capitalize,
  translateStatus,
} from '../../../../assets/helpers/funciones';
import { providerStatus } from '../../../../assets/helpers/options';
import copyImage from '../../../../images/copyImage.svg';
import { Dropzone, FileComponent } from '../../Dropzone/Dropzone';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDropzone } from 'react-dropzone';
import { notify } from '../../../../assets/helpers/toast';

import './Input.scss';
registerLocale('es', es);

export const FilterDatePicker = props => {
  const {
    label,
    bootstrap,
    minDate,
    maxDate,
    data,
    name,
    placeholder,
    value,
    onChange,
    disabled,
  } = props;
  return (
    <div className={`input-component-container ${bootstrap}`}>
      {data?.subLabel && !label ? '' : <label htmlFor={name}>{label}</label>}

      <div>
        <div className="input-sublabel-container">
          {data?.subLabel && <h6>{data?.subLabel}</h6>}
          <DatePicker
            dateFormat="dd/MM/yyyy"
            placeholderText={placeholder}
            onChange={onChange}
            selected={value}
            name="Hasta"
            locale="es"
            autoComplete="off"
            minDate={minDate || data?.minDate}
            maxDate={maxDate || data?.maxDate}
            disabled={disabled || data?.disabled}
          />
        </div>
      </div>
    </div>
  );
};

// EYE component that shows or hyde password
export const ShowPassword = props => {
  const { type, inputType, showPassword } = props;

  if (type === 'password' && inputType !== 'text') {
    return <ion-icon name="eye-outline" onClick={showPassword}></ion-icon>;
  }
  if (type === 'password' && inputType === 'text') {
    return <ion-icon name="eye-off-outline" onClick={showPassword}></ion-icon>;
  } else return null;
};

export const SelectInput = props => {
  const {
    onChange,
    bootstrap,
    label,
    name,
    register,
    options,
    errors,
    disabled,
    capitalizeOff,
  } = props;


  return (
    <div className={`input-component-container ${bootstrap}`}>
      <label htmlFor={name}>{label}</label>
      <div>
        <select
          className="input-select"
          onChange={onChange}
          {...register(name, {
            onChange: onChange,
          })}
          name={name}
          disabled={disabled}
        >
          <option value=""></option>
          {options?.map((o, i) => (
            <option key={o?._id} value={o?._id}>
              {capitalizeOff ? o?.name : capitalize(o?.name?.toLowerCase())}
            </option>
          ))}
        </select>
      </div>

      <p>{errors}</p>
    </div>
  );
};

export const Input = props => {
  const {
    bootstrap,
    label,
    type,
    title,
    name,
    register,
    placeholder,
    options,
    errors,
    data,
    subLabel,
    control,
    onChange,
    disabled,
    min,
    max,
    minDate,
    capitalizeOff,
    maxDate,
  } = props;

  const [inputType, setInputType] = useState(type);

  const showPassword = () => {
    inputType === 'password' ? setInputType('text') : setInputType('password');
  };

  let errorInput = 'i';
  if (errors) {
    errorInput = 'error-input';
  }

  if (title) {
    return (
      <>
        <div
          style={{ marginTop: '30px' }}
          className="filter-button-container"
        ></div>
        <h5 className="form-input-title">{title}</h5>
      </>
    );
  }

  if (type === 'checkbox') {
    return (
      <CheckboxInput
        subLabel={data?.subLabel || subLabel}
        bootstrap={bootstrap}
        label={label}
        name={name}
        register={register}
        errorInput={errorInput}
        errors={errors}
        onChange={onChange || data?.onChange}
      />
    );
  }

  if (type === 'select') {
    return (
      <SelectInput
        options={data?.options || options}
        bootstrap={bootstrap}
        label={label}
        type={type}
        name={name}
        register={register}
        placeholder={placeholder}
        errors={errors}
        disabled={disabled || data?.disabled}
        onChange={onChange || data?.onChange}
        capitalizeOff={capitalizeOff}
      />
    );
  }

  if (type === 'textarea') {
    return (
      <div className={`input-component-container ${bootstrap}`}>
        {data?.subLabel && !label ? '' : <label htmlFor={name}>{label}</label>}

        <div>
          <div className="input-sublabel-container">
            {data?.subLabel && <h6>{data?.subLabel}</h6>}
            <textarea
              id={name}
              className={`${errorInput}`}
              type={inputType}
              name={name}
              onChange={onChange}
              {...register(name, {
                onChange: onChange,
              })}
              placeholder={placeholder}
              autoComplete="off"
              disabled={disabled || data?.disabled}
            />
          </div>
        </div>

        <p>{errors}</p>
      </div>
    );
  }

  if (type === 'date') {
    return (
      <div className={`input-component-container ${bootstrap}`}>
        {data?.subLabel && !label ? '' : <label htmlFor={name}>{label}</label>}

        <div>
          <div className="input-sublabel-container">
            {data?.subLabel && <h6>{data?.subLabel}</h6>}
            <Controller
              control={control}
              name={name}
              render={({ field }) => (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText={placeholder}
                  onChange={date => field.onChange(date)}
                  selected={field.value}
                  locale="es"
                  autoComplete="off"
                  minDate={minDate || data?.minDate}
                  maxDate={maxDate || data?.maxDate}
                  disabled={disabled || data?.disabled}
                />
              )}
            />
          </div>
        </div>
        <p>{errors}</p>
      </div>
    );
  }

  if (name) {
    return (
      <div className={`input-component-container ${bootstrap}`}>
        {data?.subLabel && !label ? '' : <label htmlFor={name}>{label}</label>}

        <div>
          <div className="input-sublabel-container">
            {data?.subLabel && <h6>{data?.subLabel}</h6>}
            <input
              id={name}
              className={`${errorInput}`}
              type={inputType}
              name={name}
              onChange={onChange}
              {...register(name, {
                onChange: onChange,
              })}
              placeholder={placeholder}
              autoComplete="off"
              disabled={disabled || data?.disabled}
              min={min || data?.min}
              max={max || data?.max}
            />
          </div>

          <ShowPassword
            type={type}
            inputType={inputType}
            showPassword={showPassword}
          />
        </div>

        <p>{errors}</p>
      </div>
    );
  } else {
    return <div className={`input-component-container ${bootstrap}`}></div>;
  }
};

export const SelectPerPage = props => {
  const { options, label, value, onChange } = props;
  return (
    <div className="select-per-page">
      <label htmlFor="label">{label}</label>
      <select name="" id="label" onChange={e => onChange(e)} value={value}>
        {options?.map(o => (
          <option key={o} value={o}>
            {o}
          </option>
        ))}
      </select>
    </div>
  );
};

export const Select = props => {
  const { name, options, label, value, onChange } = props;
  return (
    <div className="simple-select">
      <label htmlFor="label">{label}</label>
      <select name={name} id="label" onChange={e => onChange(e)} value={value}>
        {options?.map(o => (
          <option key={o?.name} value={o?._id}>
            {capitalize(o?.name)}
          </option>
        ))}
      </select>
    </div>
  );
};

export const CheckboxInput = props => {
  const {
    bootstrap,
    subLabel,
    errorInput,
    name,
    register,
    errors,
    onChange,
  } = props;

  return (
    <div className={`input-component-container ${bootstrap}`}>
      {/* <label htmlFor={name}>{label}</label> */}
      <div className="checkbox-input-sublabel-container">
        <input
          id={name}
          className={`checkbox-input  ${errorInput}`}
          type={'checkbox'}
          name={name}
          onChange={onChange}
          {...register(name, {
            onChange: onChange,
          })}
          autoComplete="off"
        />
        <h6>{subLabel}</h6>
      </div>
      <p>{errors}</p>
    </div>
  );
};

export const SearchInput = props => {
  const { placeholder, width, value, onChange, searchAction } = props;

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      searchAction();
    }
  };

  return (
    <div className="incidence-search">
      <div className="searchImageContainers">
        <img src={search} alt="search" onClick={() => searchAction()} />
      </div>
      <input
        style={{ width: width }}
        className="service-search-input"
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={e => handleKeyDown(e)}
      />
    </div>
  );
};

export const SelectProvider = props => {
  const { onChange, bootstrap, label, name, options, errors, value } = props;

  return (
    <div className={`input-component-container ${bootstrap}`}>
      <label htmlFor={name}>{label}</label>
      <div>
        <select
          className="input-select"
          onChange={onChange}
          value={value}
          name={name}
        >
          <option value=""></option>
          {options?.map((o, i) => {
            if (!o?.isActive) {
              return (
                <option
                  style={{ color: '#D3D3D3' }}
                  key={o?._id}
                  value={o?._id}
                >
                  {capitalize(o?.contactName?.toLowerCase())}
                  &nbsp;&nbsp;( Inactivo)
                </option>
              );
            }
            if (o?.status === providerStatus.available) {
              return (
                <option key={o?._id} value={o?._id}>
                  {capitalize(o?.contactName?.toLowerCase())}
                  &nbsp;&nbsp;(
                  {translateStatus(o?.status)})
                </option>
              );
            } else {
              return (
                <option style={{ color: 'red' }} key={o?._id} value={o?._id}>
                  {capitalize(o?.contactName?.toLowerCase()) + '   '}
                  &nbsp;&nbsp;(
                  {translateStatus(o?.status)})
                </option>
              );
            }
          })}
        </select>
      </div>

      <p>{errors}</p>
    </div>
  );
};

export const PaymentSection = props => {
  const {
    payment,
    watch,
    options,
    register,
    index,
    returnAccountData,
    fileUploaded,
    setFileUploaded,
    onChange,
  } = props;

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();

      reader.onload = () => {
        // Do whatever you want with the file contents
      };
      reader.readAsArrayBuffer(file);

      if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 3;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        let files = fileUploaded;
        files[index] = acceptedFiles[0];

        setFileUploaded([...files]);
      }
    });
  }, []);

  const removeFile = index => {
    let files = fileUploaded;
    files[index] = '';
    setFileUploaded([...files]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <>
      {payment?.name?.includes('method') && (
        <CopyComponent
          watch={watch}
          payment={payment}
          options={options}
          returnAccountData={returnAccountData}
        />
      )}
      {payment?.bootstrap && (
        <Input
          key={payment?.label}
          bootstrap={payment?.bootstrap}
          label={payment?.label}
          type={payment?.type}
          name={payment?.name}
          register={register}
          placeholder={''}
          errors={payment?.errors}
          options={
            index === 0 ? options?.paymentMethods : options?.filteredMethods
          }
          min={'1'}
          disabled={payment?.disabled}
          onChange={onChange?.setBsRate}
        />
      )}

      {payment?.dropzone && (
        <div className="col-6">
          <label className="dropzone-label">{payment?.label}</label>
          {fileUploaded[index] ? (
            <FileComponent
              file={fileUploaded[index]}
              remove={() => removeFile(index)}
            />
          ) : (
            <Dropzone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              message={'(PNG, JPG, JEPG, máx. 3MB)'}
            />
          )}
        </div>
      )}
    </>
  );
};

export const CopyComponent = props => {
  const { watch, payment, options, returnAccountData } = props;

  if (watch(`${payment?.name}`) && payment?.type === 'select') {
    return (
      <CopyToClipboard
        text={returnAccountData(
          options.paymentMethods,
          watch(`${payment?.name}`)
        )}
      >
        <div className="col-3 copy-payment-data-image-button-container">
          <img
            className="copy-payment-data-image-button"
            src={copyImage}
            alt="copyImage"
          />
          <h6>Copiar datos de pago</h6>
        </div>
      </CopyToClipboard>
    );
  } else return <div className="col-3"></div>;
};
