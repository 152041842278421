import {
  GoogleMap,
  Marker,
  InfoWindow
} from '@react-google-maps/api';
import { calculateMiddlePoint } from "../../../assets/helpers/funciones"
import { useState } from 'react';
import { AltButton, ActionButton } from "../Buttons/Buttons";
import './Map.scss';


export const AllDevicesMap = (props) => {
  const { data, middlePoint, showInfoWindow, refresh, showLegend } = props;


  const [mapref, setMapRef] = useState(null);
  const [mapCenter, setMapCenter] = useState(
    calculateMiddlePoint(data)
  );


  const handleOnLoad = map => {
    setMapRef(map);
  };

  const onReload = () => {
    // if (mapref) {
    //   const newCenter = mapref?.getCenter();
    //   // const lat = newCenter?.lat()
    //   // const lng = newCenter?.lng()

    //   //   setMapCenter((prevState) => {
    //   //     return {
    //   //       ...prevState,
    //   //       lat: lat,
    //   //       lng: lng,
    //   //     };
    //   //   });
    // }
    refresh(true)

  }


  calculateMiddlePoint(data)
  if (data?.length > 0) {
    return (
      <>
        <GoogleMap
          center={middlePoint}
          zoom={8}
          onLoad={handleOnLoad}
          mapContainerStyle={{ width: '100%', height: '500px' }}
          options={{
            zoomControl: true,
            // streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: true,
          }}
        >{data?.map((d, i) => (
          <Marker
            onClick={() => showInfoWindow(i)}
            position={{ lat: d?.lat, lng: d?.lng }}
            label={''}
            icon={{
              url: d?.icon?.path, scaledSize: new window.google.maps.Size(30, 30),
            }}
          >{d?.show && d?.name &&
            <InfoWindow>
              <div className='custom-info-window'>
                <p>{d?.name} </p>
              </div>
            </InfoWindow>
            }
          </Marker>
        ))
          }
        </GoogleMap>
        <div style={{ marginTop: '36px' }} className="buttons-center-container">
          <AltButton label={"Leyenda"}
            action={() => showLegend()}
          />
          <ActionButton label={"Actualizar"} action={() => onReload()} />

        </div>

      </>
    )
  } else {
    return null
  }
}
