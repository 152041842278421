import { useEffect, useState } from 'react';
import { Header } from '../../../common/Header/Header.jsx';
import { SelectPerPage, FilterDatePicker } from '../../../common/Forms/Input/Input';
import {
  ScheduledActiveServiceTable,
} from '../../../common/Tables/Tables';
import {
  scheduledServiceTableHeader,
} from '../../../../assets/helpers/tables';
import {
  getProvidersEnum,
} from '../../../../assets/helpers/options';
import { caseService } from '../../../../services/caseService';
import { notify } from '../../../../assets/helpers/toast';
import { exists, filterParams } from '../../../../assets/helpers/funciones';
import './Scheduled.scss';
import moment from 'moment';

const itemsPerpage = [10, 25, 50, 100, 500];

export const Scheduled = () => {
  const [scheduledServiceDataTable, setScheduledServiceDataTable] = useState({
    limit: itemsPerpage[0],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    type: getProvidersEnum.CRANE,
    isInternal: false,
    isLoading: true,
    state: '',
    isActive: "",
  });
  const [sinceDate, setSinceDate] = useState(
    new Date(Date.now())
  );
  const [untilDate, setUntilDate] = useState(
    new Date(Date.now())
  );

  const handleSinceDateChange = (e) => {
    if (e > untilDate && untilDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setSinceDate(e);
      sessionStorage.setItem(`sinceDate`, e);
    }
  };
  const handleUntilDateChange = (e) => {
    if (e < sinceDate && sinceDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setUntilDate(e);
      sessionStorage.setItem(`untilDate`, e);
    }
  };

  const selectitemsPerpage = (event) => {
    setScheduledServiceDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setScheduledServiceDataTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * scheduledServiceDataTable?.limit,
      };
    });
  };


  const getProviders = (byMail) => {
    setScheduledServiceDataTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });


    let params = {
      limit: scheduledServiceDataTable?.limit,
      from: sinceDate ? moment(sinceDate)?.format('YYYY-MM-DD') : "",
      to: sinceDate ? moment(untilDate)?.format('YYYY-MM-DD') : "",
      page: scheduledServiceDataTable?.page,
    };

    caseService
      .getActiveScheduledCases(filterParams(params))
      .then((response) => {
        if (response.status === 200) {
          console.log('response:', response)
          setScheduledServiceDataTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.data,
              count: response?.data?.total,
              isLoading: false,
            };
          });
        }
        else {
          notify(`${response.message}` || 'error', 'error');
          setScheduledServiceDataTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setScheduledServiceDataTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };


  useEffect(() => {
    getProviders();
  }, [
    scheduledServiceDataTable?.page,
    scheduledServiceDataTable?.limit,
    sinceDate,
    untilDate,
  ]);




  return (
    <>
      <Header />
      <div className="service-with-container container">
        <h1 className="title-component">
          Servicios Agendados: {exists(scheduledServiceDataTable?.count)}
        </h1>
        <div className="breadcrumb-container">
          <div className="providers-buttons-container">
            <>
              <div className="col-3"></div>
              <FilterDatePicker
                bootstrap={'col-4'}
                label={'Desde'}
                type={'date'}
                name={'untilDate'}
                placeholder={''}
                onChange={handleSinceDateChange}
                value={sinceDate}
              />{' '}
              <FilterDatePicker
                bootstrap={'col-4'}
                label={'Hasta'}
                type={'date'}
                name={'untilDate'}
                placeholder={''}
                onChange={handleUntilDateChange}
                value={untilDate}
              />
            </>
          </div>
        </div>
        <div className="gray-line"></div>

        <div className="filter-button-container"></div>
        <br />
        <div className="breadcrumb-container">
          <span></span>
          <div className="service-filter-buttons-container">
            <SelectPerPage
              label={'Registros por página'}
              options={itemsPerpage}
              value={scheduledServiceDataTable?.limit}
              onChange={selectitemsPerpage}
            />
          </div>
        </div>
        <ScheduledActiveServiceTable
          header={scheduledServiceTableHeader}
          dataTable={scheduledServiceDataTable}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};


