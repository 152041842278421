import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const paymentService = {
  createPayment: async (data) => {
    const response = await authInstance
      .post(`${url}/payments/create`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
  editCashback: async (data, _id) => {
    const response = await authInstance
      .put(`${url}/paymentChange/update/${_id}`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
