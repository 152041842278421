import { fixResponseJSON, filterParams } from '../assets/helpers/funciones'
import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const servicesService = {
  createAVService: async (data) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      method: 'POST',
      body: data,
    }

    const response = await fetch(`${url}/services/createAv`, myInit)
      .then(async (response) => {
        return fixResponseJSON(response)
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  conciliateService: async (data) => {
    const response = await authInstance
      .put(`${url}/services/reconcile`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getServicesByOperator: async (params) => {
    const response = await authInstance
      .get(
        `${url}/services/servicesByOperator/?` +
          new URLSearchParams(filterParams(params))
      )
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  cancelService: async (data, serviceId) => {
    const response = await authInstance
      .post(`${url}/services/${serviceId}/cancel`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getExportables: async (params) => {
    for (const key in params) {
      if (params[key] === undefined || params[key] === '') {
        delete params[key]
      }
    }

    const response = await authInstance
      .get(
        `${url}/services/exportablePendingPayment?` +
          new URLSearchParams(params)
      )
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
  getExportableAdmin: async (params) => {
    for (const key in params) {
      if (params[key] === undefined || params[key] === '') {
        delete params[key]
      }
    }

    const response = await authInstance
      .get(`${url}/services/exportableAdmin?` + new URLSearchParams(params))
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  uploadAVImage: async (file, serviceId) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      method: 'PUT',
      body: file,
    }

    const response = await fetch(
      `${url}/services/${serviceId}/uploadAvImage`,
      myInit
    )
      .then(async (response) => {
        return fixResponseJSON(response)
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  uploadTowImage: async (file, serviceId) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      method: 'PUT',
      body: file,
    }

    const response = await fetch(
      `${url}/services/${serviceId}/uploadTowImages`,
      myInit
    )
      .then(async (response) => {
        return fixResponseJSON(response)
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getUploadedImages: async (serviceId) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      method: 'GET',
    }

    const response = await fetch(
      `${url}/services/${serviceId}/getUploadedImages`,
      myInit
    )
      .then(async (response) => {
        return fixResponseJSON(response)
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
  updateProvider: async (data, serviceId) => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      method: 'PUT',
      body: JSON.stringify(data),
    }

    const response = await fetch(
      `${url}/services/${serviceId}/updateProvider`,
      myInit
    )
      .then(async (response) => {
        return fixResponseJSON(response)
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  createTowService: async (data) => {
    const response = await authInstance
      .post(`${url}/services/createTow`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  updateTowService: async (data, serviceId) => {
    const myInit = {
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      method: 'PUT',
      body: data,
    }

    const response = await fetch(
      `${url}/services/${serviceId}/updateTow`,
      myInit
    )
      .then(async (response) => {
        return fixResponseJSON(response)
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  updateAV: async (caseId, data) => {
    const response = await authInstance
      .put(`${url}/services/${caseId}/updateAv`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
