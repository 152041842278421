import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { PageNotFound } from './components/views/PageNotFound/PageNotFound'
import { ProtectedRoute } from './components/common/ProtectedRoute/ProtectedRoute'
import { Login } from './components/views/Login/Login'
import {
  ExternalProviders,
  InternalProviders,
  Fleet,
} from './components/views/Providers/Providers'
import { Scheduled } from './components/views/Stats/Scheduled/Scheduled.jsx'
import { FleetLocation } from './components/views/Providers/FleetLocation'
import { Charge } from './components/views/Providers/Charge/Charge'
import {
  NewProvider,
  NewInternalProvider,
  NewVehicularAsset,
} from './components/views/Providers/NewProvider/NewProvider'
import {
  EditExternalProvider,
  EditInternalProvider,
  EditVehicularAsset,
} from './components/views/Providers/EditProvider/EditProvider'
import { Services } from './components/views/Services/Services'
import { Stats } from './components/views/Services/Stats'
import { Exportables } from './components/views/Stats/Exportables/Exportables'
import { ProvidersPayment } from './components/views/Providers/ProvidersPayment/ProvidersPayment.jsx'
import { ParticularServicesCounter } from './components/views/Providers/ParticularServicesCounter/ParticularServicesCounter.jsx'
import { Operations } from './components/views/Stats/Operations/Operations'
import { Indicators } from './components/views/Stats/Indicators/Indicators'
import { Reviews } from './components/views/Reviews/Reviews'
import { ParticularIncidence } from './components/views/Incidence/ParticularIncidence'
import { AssingIncidence } from './components/views/Incidence/AssingIncidence'
import { AffiliatedIncidence } from './components/views/Incidence/AffiliatedIncidence'
import { ExpressCheck } from './components/views/ExpressCheck/ExpressCheck'
import { IncidenceDetail } from './components/views/Incidence/IncidenceDetail'
import { EditCase } from './components/views/Incidence/EditCase'
import { FleetConfig } from './components/views/Configurations/Configurations'
import { OperationsUsers } from './components/views/OperationsUsers/OperationsUsers'
import { NewFleet } from './components/views/Configurations/Fleets/Fleets'
import { Parameters } from './components/views/Configurations/Parameters/Parameters'
import { Garage } from './components/views/Garage/Garage'
import 'react-toastify/dist/ReactToastify.css'
import { operationsRoles } from './assets/helpers/roles'
import './App.scss'

function App() {
  const {
    operationsAdmin,
    operationsDev,
    operationsSupervisor,
    operationsOperator,
    OPERATIONS_FLOTA_AV,
    OPERATIONS_FLOTA_GRUA,
  } = operationsRoles

  return (
    <React.Fragment>
      <div className="app-content">
        <div>
          <Switch>
            <Route
              path="/login"
              render={(props) => {
                return <Login {...props} props={props} />
              }}
            />
            <ProtectedRoute
              path="/services"
              level={[
                operationsDev,
                operationsAdmin,
                operationsSupervisor,
                operationsOperator,
                OPERATIONS_FLOTA_AV,
                OPERATIONS_FLOTA_GRUA,
              ]} // Tipos de usuario autorizados a esta ruta
              component={Services}
            />
            <ProtectedRoute
              path="/providers-stats"
              level={[
                operationsDev,
                operationsAdmin,
                operationsSupervisor,
                operationsOperator,
                OPERATIONS_FLOTA_AV,
                OPERATIONS_FLOTA_GRUA,
              ]} // Tipos de usuario autorizados a esta ruta
              component={Stats}
            />
            {/* Estadisticas */}
            <ProtectedRoute
              path="/stats/exportable"
              level={[operationsDev, operationsAdmin, operationsSupervisor]} // Tipos de usuario autorizados a esta ruta
              component={Exportables}
            />
            <ProtectedRoute
              path="/stats/reviews"
              level={[operationsDev, operationsAdmin, operationsSupervisor]} // Tipos de usuario autorizados a esta ruta
              component={Reviews}
            />
            <ProtectedRoute
              path="/stats/indicators"
              level={[operationsDev, operationsAdmin, operationsSupervisor]} // Tipos de usuario autorizados a esta ruta
              component={Indicators}
            />{' '}
            <ProtectedRoute
              path="/stats/operations"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={Operations}
            />
            {/* Incidence */}
            <ProtectedRoute
              path="/incidence/assign-provider/:caseId/charge"
              level={[
                operationsDev,
                operationsAdmin,
                operationsSupervisor,
                operationsOperator,
              ]} // Tipos de usuario autorizados a esta ruta
              component={Charge}
            />
            <ProtectedRoute
              path="/incidence/assign-provider/:caseId/service/:serviceId"
              level={[
                operationsDev,
                operationsAdmin,
                operationsSupervisor,
                operationsOperator,
              ]} // Tipos de usuario autorizados a esta ruta
              component={AssingIncidence}
            />{' '}
            <ProtectedRoute
              path="/incidence/detail/:caseId"
              level={[
                operationsDev,
                operationsAdmin,
                operationsSupervisor,
                operationsOperator,
              ]} // Tipos de usuario autorizados a esta ruta
              component={IncidenceDetail}
            />
            <ProtectedRoute
              path="/incidence/edit/:caseId"
              level={[
                operationsDev,
                operationsAdmin,
                operationsSupervisor,
                operationsOperator,
              ]} // Tipos de usuario autorizados a esta ruta
              component={EditCase}
            />
            <ProtectedRoute
              path="/incidence/create/affiliated"
              level={[
                operationsDev,
                operationsAdmin,
                operationsSupervisor,
                operationsOperator,
              ]} // Tipos de usuario autorizados a esta ruta
              component={AffiliatedIncidence}
            />{' '}
            <ProtectedRoute
              path="/incidence/create/particular"
              level={[
                operationsDev,
                operationsAdmin,
                operationsSupervisor,
                operationsOperator,
              ]} // Tipos de usuario autorizados a esta ruta
              component={ParticularIncidence}
            />
            {/* Proveedores de Gruas */}
            <ProtectedRoute
              path="/providers/external/create"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={NewProvider}
            />
            <ProtectedRoute
              path="/providers/providers-payments"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={ProvidersPayment}
            />
            <ProtectedRoute
              path="/stats/operators-counter"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={ParticularServicesCounter}
            />
            <ProtectedRoute
              path="/stats/scheduled-service"
              level={[operationsDev, operationsAdmin, operationsOperator]} // Tipos de usuario autorizados a esta ruta
              component={Scheduled}
            />
            <ProtectedRoute
              path="/providers/fleet-location"
              level={[
                operationsAdmin,
                operationsDev,
                operationsSupervisor,
                operationsOperator,
                OPERATIONS_FLOTA_AV,
                OPERATIONS_FLOTA_GRUA,
              ]} // Tipos de usuario autorizados a esta ruta
              component={FleetLocation}
            />
            <ProtectedRoute
              path="/providers/external/edit/:providerId"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={EditExternalProvider}
            />{' '}
            <ProtectedRoute
              path="/providers/external"
              level={[operationsDev, operationsAdmin, operationsOperator]} // Tipos de usuario autorizados a esta ruta
              component={ExternalProviders}
            />
            <ProtectedRoute
              path="/incidence/express-check/:caseId"
              level={[
                operationsDev,
                operationsAdmin,
                OPERATIONS_FLOTA_GRUA,
                OPERATIONS_FLOTA_AV,
              ]} // Tipos de usuario autorizados a esta ruta
              component={ExpressCheck}
            />
            {/* Proveedores de Motos */}
            <ProtectedRoute
              path="/providers/internal/create"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={NewInternalProvider}
            />{' '}
            <ProtectedRoute
              path="/providers/internal/edit/:providerId"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={EditInternalProvider}
            />{' '}
            <ProtectedRoute
              path="/providers/internal"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={InternalProviders}
            />{' '}
            <ProtectedRoute
              path="/providers/fleet/create"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={NewVehicularAsset}
            />{' '}
            <ProtectedRoute
              path="/providers/fleet/edit/:vehicleId"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={EditVehicularAsset}
            />
            <ProtectedRoute
              path="/providers/fleet"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={Fleet}
            />
            <ProtectedRoute
              path="/garage/billing"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={Garage}
            />
            {/* Configurations */}
            <ProtectedRoute
              path="/config/fleet/create"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={NewFleet}
            />
            <ProtectedRoute
              path="/config/fleet"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={FleetConfig}
            />
            <ProtectedRoute
              path="/config/users"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={OperationsUsers}
            />
            <ProtectedRoute
              path="/config/parameters"
              level={[operationsDev, operationsAdmin]} // Tipos de usuario autorizados a esta ruta
              component={Parameters}
            />{' '}
            <Route
              path="/not-found"
              render={(props) => {
                return <PageNotFound {...props} props={props} />
              }}
            />
            <Route
              path="/"
              exact
              render={(props) => {
                return (
                  <ProtectedRoute
                    path="/"
                    level={['ADMIN']} // Tipos de usuario autorizados a esta ruta
                    component={Login}
                  />
                )
              }}
            />
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </div>
    </React.Fragment>
  )
}

export default App
