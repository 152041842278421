import * as yup from 'yup'
import { salesRoles } from '../../assets/helpers/roles'
import { PaymentStatus, userType } from '../helpers/options'

// File that contains the validation schemas for all forms

const passwordRegex =
  /^(?:$|(?=.*[A-Z])(?=.*[0-9].*[0-9])(?=.*[!@#$%^&*()\-_+={}|;':",<.>/?`~]).{8,})$/

export const LoginSchema = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .required('Obligatorio')
      .email(t('schema.invalid', { label: t('form.labels.email') })),
    password: yup
      .string()
      .min(
        3,
        t('schema.min_length', {
          label: t('form.labels.password'),
          characters: 3,
        })
      )
      .max(
        50,
        t('schema.max_length', {
          label: t('form.labels.password'),
          characters: 50,
        })
      )
      .required('Obligatorio'),
  })
}

export const UpdateTrackingSchema = (t) => {
  return yup.object().shape({
    type: yup.string().required('Obligatorio'),
    message: yup.string().required('Obligatorio'),
    note: yup.string(),
  })
}

export const filterCasesSchema = (t) => {
  return yup.object().shape({
    sinceDate: yup.string().required('Obligatorio'),
    untilDate: yup.string().required('Obligatorio'),
  })
}

export const filterExportableSchema = (t) => {
  return yup.object().shape({
    sinceDate: yup.string().required('Obligatorio'),
    untilDate: yup.string().required('Obligatorio'),
  })
}

export const CancelServiceSchema = (t) => {
  return yup.object().shape({
    cancellationReason: yup.string().required('Obligatorio'),
    cancellationDescription: yup.string().required('Obligatorio'),
  })
}

export const CancelCaseSchema = (t) => {
  return yup.object().shape({
    cancelationReason: yup.string().required('Obligatorio'),
    cancelationReasonDescription: yup.string().required('Obligatorio'),
  })
}

export const FailedCaseSchema = (t) => {
  return yup.object().shape({
    failedReason: yup.string().required('Obligatorio'),
  })
}

export const ChangeOperatorSchema = (t) => {
  return yup.object().shape({
    user: yup.string().required('Obligatorio'),
  })
}

export const FinishServiceSchema = (t) => {
  return yup.object().shape({
    providerFailureReason: yup.string().required('Obligatorio'),
    providerFailureDescription: yup.string().required('Obligatorio'),
  })
}

export const CreateProviderSchema = (t) => {
  return yup.object().shape({
    companyName: yup.string().required('Obligatorio'),
    contactName: yup.string().required('Obligatorio'),
    identification: yup.string().required('Obligatorio'),
    identificationType: yup.string().required('Obligatorio'),
    phonePrefix: yup.string().required('Obligatorio'),
    phoneNumber: yup
      .string()
      .min(7, 'Ingrese un telefono valido')
      .max(7, 'Ingrese un telefono valido')
      .required('Obligatorio'),
    type: yup.string().required('Obligatorio'),
    state: yup.string().required('Obligatorio'),
    isInternal: yup.boolean().required('Obligatorio'),
    isActive: yup.boolean().required('Obligatorio'),
  })
}

export const CreateVehicularAssetSchema = (t) => {
  return yup.object().shape({
    class: yup.string().required('Obligatorio'),
    brand: yup.string().required('Obligatorio'),
    model: yup.string().required('Obligatorio'),
    plate: yup.string().required('Obligatorio'),
    color: yup.string().required('Obligatorio'),
    alias: yup.string().required('Obligatorio'),
    year: yup.string().required('Obligatorio'),
    purchaseDate: yup.string().required('Obligatorio'),
    description: yup.string(),
  })
}

export const CreateIncidenceSchema = (t) => {
  return yup.object().shape({
    originLocationStatus: yup.string().required('Obligatorio'),

    isOriginBasement: yup.boolean().required('Obligatorio'),
    basementLevel: yup.string().when('isOriginBasement', {
      is: true,
      then: yup.string().required('Obligatorio'),
    }),
    originState: yup.string().required('Obligatorio'),
    originCity: yup.string().required('Obligatorio'),
    originAddress: yup.string().required('Obligatorio'),

    contactNumberPrefix: yup.string().required('Obligatorio'),
    contactNumber: yup
      .string()
      .required('Obligatorio')
      .min(7, 'Ingrese un telefono valido')
      .max(7, 'Ingrese un telefono valido'),
    customerFailureReason: yup.string().required('Obligatorio'),
    originReferencePoint: yup.string(),
    customerFailureDescription: yup.string(),
    withOutDestinyAddress: yup.boolean(),
    thirdPartyService: yup.boolean(),
    // Destiny Location Schema
    destinyLocationStatus: yup.string().when('withOutDestinyAddress', {
      is: false,
      then: yup.string().required('Obligatorio'),
    }),
    destinyState: yup.string().when('withOutDestinyAddress', {
      is: false,
      then: yup.string().required('Obligatorio'),
    }),
    destinyCity: yup.string().when('withOutDestinyAddress', {
      is: false,
      then: yup.string().required('Obligatorio'),
    }),
    destinyAddress: yup.string().when('withOutDestinyAddress', {
      is: false,
      then: yup.string().required('Obligatorio'),
    }),
    caseKm: yup.string().when('withOutDestinyAddress', {
      is: false,
      then: yup.string().required('Debe calcular la distancia'),
    }),
    estimatedTime: yup.string().when('withOutDestinyAddress', {
      is: false,
      then: yup.string(),
    }),
    // Third party service schema
    thirdPartyParticularCustomerName: yup.string().when('thirdPartyService', {
      is: true,
      then: yup.string().required('Obligatorio'),
    }),
    thirdPartyParticularCustomerLastName: yup
      .string()
      .when('thirdPartyService', {
        is: true,
        then: yup.string().required('Obligatorio'),
      }),
    thirdPartyServicePhonePrefix: yup.string().when('thirdPartyService', {
      is: true,
      then: yup.string().required('Obligatorio'),
    }),
    thirdPartyServicePhoneNumber: yup.string().when('thirdPartyService', {
      is: true,
      then: yup
        .string()
        .required('Obligatorio')
        .min(7, 'Ingrese un telefono valido')
        .max(7, 'Ingrese un telefono valido'),
    }),
    thirdPartyVehicleBrand: yup.string().when('thirdPartyService', {
      is: true,
      then: yup.string().required('Obligatorio'),
    }),
    thirdPartyVehicleModel: yup.string().when('thirdPartyService', {
      is: true,
      then: yup.string().required('Obligatorio'),
    }),
    thirdPartyVehicleColor: yup.string().when('thirdPartyService', {
      is: true,
      then: yup.string().required('Obligatorio'),
    }),
    thirdPartyVehicleYear: yup.string().when('thirdPartyService', {
      is: true,
      then: yup.string().required('Obligatorio'),
    }),
    thirdPartyVehiclePlate: yup.string().when('thirdPartyService', {
      is: true,
      then: yup.string().required('Obligatorio'),
    }),
  })
}

export const providerPaymentSchema = (totalAmount, dataType) => {
  return yup.object().shape({
    billNumber: yup.string('').optional(''),
    providerMethods: yup
      .number('')
      .min(1, 'Ingrese una cantidad valida de metodos de pago')
      .required('Obligatorio'),
    method1: yup.string().required('Obligatorio'),
    amount1: yup
      .string()
      .required('Obligatorio')
      .when(['providerMethods', 'billNumber'], {
        is: (providerMethods, billNumber) => providerMethods > 0,
        then: yup
          .string()
          .test(
            'is-equal-to-totalAmount',
            'La suma de los montos debe ser igual al total',
            function (value) {
              const { parent } = this
              const adjustedTotalAmount = parent.billNumber
                ? (totalAmount * 1.16).toFixed(2)
                : totalAmount.toFixed(2)
              if (parent.providerMethods > 1) {
                const amount2 = parseFloat(parent.amount2 || 0)
                return (
                  parseFloat(value) + amount2 ===
                  parseFloat(adjustedTotalAmount)
                )
              }
              return parseFloat(value) === parseFloat(adjustedTotalAmount)
            }
          ),
      }),
    amountBsS1: yup.string().required('Obligatorio'),

    method2: yup.string().when('providerMethods', {
      is: (providerMethods) => providerMethods > 1,
      then: yup
        .string('')
        .min(0, 'Ingrese un monto valido')
        .required('Obligatorio'),
    }),
    amount2: yup.string().when(['providerMethods', 'billNumber'], {
      is: (providerMethods, billNumber) => providerMethods > 1,
      then: yup
        .string()
        .required('Obligatorio')
        .test(
          'is-equal-to-totalAmount',
          'La suma de los montos debe ser igual al total',

          function (value) {
            const { parent } = this
            const adjustedTotalAmount = parent.billNumber
              ? (totalAmount * 1.16).toFixed(2)
              : totalAmount.toFixed(2)
            const amount1 = parseFloat(parent.amount1 || 0)
            return (
              parseFloat(value) + amount1 === parseFloat(adjustedTotalAmount)
            )
          }
        ),
    }),
    amountBsS2: yup.string().when('providerMethods', {
      is: (providerMethods) => providerMethods > 1,
      then: yup
        .string('')
        .min(0, 'Ingrese un monto valido')
        .required('Obligatorio'),
    }),
    observation: yup.string().optional('Obligatorio'),
    providerPaidDate: yup.string().optional(),
    ExchangeRate: yup.string().optional(),
  })
}

export const CreateUsersSchema = () => {
  return yup.object().shape({
    firstName: yup.string().required('Obligatorio'),
    lastName: yup.string().required('Obligatorio'),
    identification: yup.string().required('Obligatorio'),
    email: yup.string().email().required('Obligatorio'),
    password: yup
      .string()
      .trim()
      .matches(passwordRegex, 'Contraseña en formato invalido')
      .required('Obligatorio'),
    confirmPassword: yup
      .string()
      .matches(passwordRegex, 'Contraseña en formato invalido')
      .oneOf(
        [yup.ref('password'), null],
        'Contraseña  y confirmación deben coincidir'
      )
      .required(''),
    role: yup.string().required('Obligatorio'),
    shift: yup.string().required('Obligatorio'),
    module: yup.string().required('Obligatorio'),
    status: yup.string().required('Obligatorio'),
    type: yup.string().when('role', {
      is: salesRoles?.SALES_COUNTER,
      then: yup.string().required('Obligatorio'),
    }),
  })
}

export const EditUsersSchema = () => {
  return yup.object().shape({
    firstName: yup.string().optional(''),
    lastName: yup.string().optional(''),
    identification: yup.string().optional(''),
    email: yup.string().email().optional(''),
    password: yup
      .string()
      .trim()
      .matches(passwordRegex, 'Contraseña en formato invalido'),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        'Contraseña  y confirmación deben coincidir'
      ),
    status: yup.string().optional(''),
  })
}

export const CreateAVFormSchema = (t) => {
  return yup.object().shape({
    paymentStatus: yup.string().required('Obligatorio'),
    contact: yup.string(),
    vehicleYear: yup.string(),
    vehicleBrand: yup.string(),
    vehicleModel: yup.string(),
    vehiclePlate: yup.string(),
    vehicleColor: yup.string(),
    estimatedTime: yup.string(),
    price: yup.number().required('Obligatorio'),
    authorizedBy: yup.string().when('paymentStatus', {
      is: (paymentStatus) => [PaymentStatus?.COURTESY]?.includes(paymentStatus),
      then: yup.string('').required('Obligatorio'),
    }),
    pvp: yup.number().when('paymentStatus', {
      is: (paymentStatus) =>
        [
          PaymentStatus?.DE_CONTADO,
          PaymentStatus?.CUENTA_POR_COBRAR,
          PaymentStatus?.COURTESY,
        ]?.includes(paymentStatus),
      then: yup
        .number('')
        .min(0, 'Ingrese un monto valido')
        .required('Obligatorio'),
    }),
    paymentMethods: yup.number().when(['pvp', 'paymentStatus'], {
      is: (pvp, paymentStatus) =>
        pvp > 0 &&
        ![
          PaymentStatus?.N_A,
          PaymentStatus?.CUENTA_POR_COBRAR,
          PaymentStatus?.COURTESY,
        ]?.includes(paymentStatus),
      then: yup
        .number('Obligatorio')
        .min(1, 'Ingrese almenos un metodo de pago')
        .max(5, 'Ha superado el limite de métodos  de pago')
        .required('Obligatorio'),
    }),
    method0: yup.string().when(['pvp', 'paymentStatus'], {
      is: (pvp, paymentStatus) =>
        pvp > 0 &&
        ![
          PaymentStatus?.N_A,
          PaymentStatus?.CUENTA_POR_COBRAR,
          PaymentStatus?.COURTESY,
        ]?.includes(paymentStatus),
      then: yup.string().required('Obligatorio'),
    }),
    reference0: yup.string().when(['pvp', 'paymentStatus'], {
      is: (pvp, paymentStatus) =>
        pvp > 0 &&
        ![
          PaymentStatus?.N_A,
          PaymentStatus?.CUENTA_POR_COBRAR,
          PaymentStatus?.COURTESY,
        ]?.includes(paymentStatus),
      then: yup.string().required('Obligatorio'),
    }),
    amount0: yup.string().when(['pvp', 'paymentStatus'], {
      is: (pvp, paymentStatus) =>
        pvp > 0 &&
        ![
          PaymentStatus?.N_A,
          PaymentStatus?.CUENTA_POR_COBRAR,
          PaymentStatus?.COURTESY,
        ]?.includes(paymentStatus),
      then: yup.string().required('Obligatorio'),
    }),
    amountBsS0: yup.string().when(['pvp', 'paymentStatus'], {
      is: (pvp, paymentStatus) =>
        pvp > 0 &&
        ![
          PaymentStatus?.N_A,
          PaymentStatus?.CUENTA_POR_COBRAR,
          PaymentStatus?.COURTESY,
        ]?.includes(paymentStatus),
      then: yup.string().required('Obligatorio'),
    }),
  })
}

export const CreatePaymentSchema = (t) => {
  return yup.object().shape({
    paymentDifference: yup.number(),
    accumulatedAmount: yup.number(),
    price: yup.number().required('Obligatorio'),
    pvp: yup.number().when('paymentStatus', {
      is: (paymentStatus) =>
        [PaymentStatus?.DE_CONTADO, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
          paymentStatus
        ),
      then: yup
        .number('')
        .min(0, 'Ingrese un monto valido')
        .required('Obligatorio'),
    }),
    amountHandling: yup.number('').optional(),
    amountExcess: yup.number('').optional(),
    paymentDate: yup.string().optional(''),
    paymentMethods: yup
      .number()
      .when(['pvp', 'paymentStatus', 'paymentDifference'], {
        is: (pvp, paymentStatus, paymentDifference) =>
          pvp > 0 &&
          paymentDifference > 0 &&
          ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
            paymentStatus
          ),
        then: yup
          .number('Obligatorio')
          .min(1, 'Ingrese almenos un metodo de pago')
          .max(5, 'Ha superado el limite de métodos  de pago')
          .required('Obligatorio'),
      }),
    method0: yup.string().when(['pvp', 'paymentStatus', 'paymentDifference'], {
      is: (pvp, paymentStatus, paymentDifference) =>
        pvp > 0 &&
        paymentDifference > 0 &&
        ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
          paymentStatus
        ),
      then: yup.string().required('Obligatorio'),
    }),
    reference0: yup
      .string()
      .when(
        ['pvp', 'paymentStatus', 'paymentDifference', 'paymentDifference'],
        {
          is: (pvp, paymentStatus, paymentDifference) =>
            pvp > 0 &&
            paymentDifference > 0 &&
            ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
              paymentStatus
            ),
          then: yup.string().required('Obligatorio'),
        }
      ),
    amount0: yup.string().when(['pvp', 'paymentStatus', 'paymentDifference'], {
      is: (pvp, paymentStatus, paymentDifference) =>
        pvp > 0 &&
        paymentDifference > 0 &&
        ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
          paymentStatus
        ),
      then: yup.string().required('Obligatorio'),
    }),
    amountBsS0: yup
      .string()
      .when(['pvp', 'paymentStatus', 'paymentDifference'], {
        is: (pvp, paymentStatus, paymentDifference) =>
          pvp > 0 &&
          paymentDifference > 0 &&
          ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
            paymentStatus
          ),
        then: yup.string().required('Obligatorio'),
      }),
  })
}

export const editCashbackSchema = (t) => {
  return yup.object().shape({
    method: yup.string().required('Obligatorio'),
    amount: yup.number().required('Obligatorio'),
    amountBs: yup.number().required('Obligatorio'),
    date: yup.string().required('Obligatorio'),
    ref: yup.string().required('Obligatorio'),
  })
}

export const ScheduleServiceSchema = (t) => {
  return yup.object().shape({
    date: yup.string().required('Obligatorio'),
    time: yup.string().required('Obligatorio'),
  })
}

export const payTowServiceModal = (t) => {
  return yup.object().shape({
    provider: yup.string().required('Obligatorio'),
    contact: yup.string(),
    vehicleYear: yup.string(),
    vehicleBrand: yup.string(),
    vehicleModel: yup.string(),
    vehiclePlate: yup.string(),
    vehicleColor: yup.string(),
    paymentStatus: yup.string().required('Obligatorio'),
    price: yup.number().required('Obligatorio'),
    paid: yup.number().optional(''),
    paidBs: yup.number().optional(''),
    isInternal: yup.string().required('Obligatorio'),
    externalTowReason: yup.string().when('isInternal', {
      is: (isInternal) => [userType?.EXTERNO]?.includes(isInternal),
      then: yup.string('').required('Obligatorio'),
    }),
    authorizedBy: yup.string().when('paymentStatus', {
      is: (paymentStatus) => [PaymentStatus?.COURTESY]?.includes(paymentStatus),
      then: yup.string('').required('Obligatorio'),
    }),
    pvp: yup.number().when('paymentStatus', {
      is: (paymentStatus) =>
        [PaymentStatus?.DE_CONTADO, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
          paymentStatus
        ),
      then: yup
        .number('')
        .min(0, 'Ingrese un monto valido')
        .required('Obligatorio'),
    }),
    amountHandling: yup.number().when('paymentStatus', {
      is: (paymentStatus) =>
        [PaymentStatus?.DE_CONTADO, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
          paymentStatus
        ),
      then: yup
        .number('')
        .min(0, 'Ingrese un monto valido')
        .required('Obligatorio'),
    }),
    amountExcess: yup.number().when('paymentStatus', {
      is: (paymentStatus) =>
        [PaymentStatus?.DE_CONTADO, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
          paymentStatus
        ),
      then: yup
        .number('')
        .min(0, 'Ingrese un monto valido')
        .required('Obligatorio'),
    }),
    paymentMethods: yup.number().when(['pvp', 'paymentStatus'], {
      is: (pvp, paymentStatus) =>
        pvp > 0 &&
        ![
          PaymentStatus?.N_A,
          PaymentStatus?.CUENTA_POR_COBRAR,
          PaymentStatus?.COURTESY,
        ]?.includes(paymentStatus),
      then: yup
        .number('Obligatorio')
        .min(1, 'Ingrese almenos un metodo de pago')
        .max(5, 'Ha superado el limite de métodos  de pago')
        .required('Obligatorio'),
    }),
    method0: yup.string().when(['pvp', 'paymentStatus'], {
      is: (pvp, paymentStatus) =>
        pvp > 0 &&
        ![
          PaymentStatus?.N_A,
          PaymentStatus?.CUENTA_POR_COBRAR,
          PaymentStatus?.COURTESY,
        ]?.includes(paymentStatus),
      then: yup.string().required('Obligatorio'),
    }),
    reference0: yup.string().when(['pvp', 'paymentStatus'], {
      is: (pvp, paymentStatus) =>
        pvp > 0 &&
        ![
          PaymentStatus?.N_A,
          PaymentStatus?.CUENTA_POR_COBRAR,
          PaymentStatus?.COURTESY,
        ]?.includes(paymentStatus),
      then: yup.string().required('Obligatorio'),
    }),
    amount0: yup.string().when(['pvp', 'paymentStatus'], {
      is: (pvp, paymentStatus) =>
        pvp > 0 &&
        ![
          PaymentStatus?.N_A,
          PaymentStatus?.CUENTA_POR_COBRAR,
          PaymentStatus?.COURTESY,
        ]?.includes(paymentStatus),
      then: yup.string().required('Obligatorio'),
    }),
    amountBsS0: yup.string().when(['pvp', 'paymentStatus'], {
      is: (pvp, paymentStatus) =>
        pvp > 0 &&
        ![
          PaymentStatus?.N_A,
          PaymentStatus?.CUENTA_POR_COBRAR,
          PaymentStatus?.COURTESY,
        ]?.includes(paymentStatus),
      then: yup.string().required('Obligatorio'),
    }),
  })
}

export const CreateParticularCustomer = (t) => {
  return yup.object().shape({
    firstName: yup.string().required('Obligatorio'),
    lastName: yup.string().required('Obligatorio'),
    phonePrefix: yup.string().required('Obligatorio'),
    phoneNumber: yup
      .string()
      .required('Obligatorio')
      .min(7, 'Ingrese un telefono valido')
      .max(7, 'Ingrese un telefono valido'),
    vehicleBrand: yup.string().required('Obligatorio'),
    vehicleModel: yup.string().required('Obligatorio'),
    vehicleYear: yup
      .number()
      .min(1940, 'El año del vehiculo debe ser mayor a 1940')
      .max(new Date().getFullYear() + 1, 'Ingrese un año valido')
      .required('Obligatorio'),
    vehicleColor: yup.string().required('Obligatorio'),
    vehiclePlate: yup.string().required('Obligatorio'),
  })
}

export const chargeSchema = (t) => {
  return yup.object().shape({
    name1: yup.string(),
    name2: yup.string(),
    name3: yup.string(),
    name4: yup.string(),
    name5: yup.string(),
    name6: yup.string(),
    name7: yup.string(),
    name8: yup.string(),
    name9: yup.string(),
  })
}

export const NewInternalProviderSchema = (t) => {
  return yup.object().shape({
    contactName: yup.string().required('Obligatorio'),
    identification: yup.string().required('Obligatorio'),
    identificationType: yup.string().required('Obligatorio'),
    phonePrefix: yup.string().required('Obligatorio'),
    phoneNumber: yup
      .string()
      .min(7, 'Ingrese un telefono valido')
      .max(7, 'Ingrese un telefono valido')
      .required('Obligatorio'),
    type: yup.string().required('Obligatorio'),
    state: yup.string().required('Obligatorio'),
    isInternal: yup.boolean().required('Obligatorio'),
    isActive: yup.boolean().required('Obligatorio'),
  })
}

export const RegisterSchema = (t) => {
  return yup.object().shape({
    firstName: yup.string().required('Obligatiorio'),
    lastName: yup.string().required('Obligatiorio'),
    email: yup
      .string()
      .required(t('schema.required', { label: t('form.labels.email') }))
      .email(t('schema.invalid', { label: t('form.labels.email') })),
    password: yup
      .string()
      .min(
        3,
        t('schema.min_length', {
          label: t('form.labels.password'),
          characters: 3,
        })
      )
      .max(
        50,
        t('schema.max_length', {
          label: t('form.labels.password'),
          characters: 50,
        })
      )
      .required(t('schema.required', { label: t('from.labels.password') })),
    confirmPassword: yup.string().oneOf([yup.ref('password'), 'as']),
  })
}

export const CreateEmployeeSchema = (t) => {
  return yup.object().shape({
    firstName: yup.string().required('Obligatiorio'),
    lastName: yup.string().required('Obligatiorio'),
    email: yup
      .string()
      .required(t('Obligatorio', { label: 'Obligatorio' }))
      .email('Formato invalido'),
    phone: yup.string().required('Obligatorio'),
    baseSalary: yup.number().required('Obligatorio'),
    cryptoAddress: yup.string().required('Obligatorio'),
  })
}

export const RegisterStepTwoSchema = (t) => {
  return yup.object().shape({
    companyName: yup.string().required('Obligatorio'),
    companySector: yup.string().required('Obligatorio'),
  })
}

export const revisionVehicleSchema = (t) => {
  return yup.object().shape({
    code: yup.string().optional(''),
    plate: yup.string().optional(''),
    year: yup.string().optional(''),
    model: yup.string().optional(''),
    brand: yup.string().optional(''),
    color: yup.string().optional(''),
    phone: yup.string().optional(''),
    particularCustomer: yup.string().optional(''),
    // Required fields
    coolantLevel: yup.string().required('Obligatorio'),
    engineOilLevel: yup.string().required('Obligatorio'),
    brakeFluidLevel: yup.string().required('Obligatorio'),
    gearboxOilLevel: yup.string().required('Obligatorio'),
    powerSteeringFluidLevel: yup.string().required('Obligatorio'),
    // Required fields
    engineOilCondition: yup.string().required('Obligatorio'),
    coolantFluidCondition: yup.string().required('Obligatorio'),
    powerSteeringFluidCondition: yup.string().required('Obligatorio'),
    brakeFluidCondition: yup.string().required('Obligatorio'),
    gearboxOilCondition: yup.string().required('Obligatorio'),
    timingBeltCondition: yup.string().required('Obligatorio'),
    uniqueBeltCondition: yup.string().required('Obligatorio'),
    alternatorBeltCondition: yup.string().required('Obligatorio'),
    airConditionerBeltCondition: yup.string().required('Obligatorio'),
    steeringPumpBeltCondition: yup.string().required('Obligatorio'),
    pulleysCondition: yup.string().required('Obligatorio'),
    tensorCondition: yup.string().required('Obligatorio'),
    upperHosesCondition: yup.string().required('Obligatorio'),
    lowerHosesCondition: yup.string().required('Obligatorio'),
    thermostatCondition: yup.string().required('Obligatorio'),
    coolantTankCondition: yup.string().required('Obligatorio'),
    // required fields
    valveCoverLeak: yup.string().required('Obligatorio'),
    oilPanLeak: yup.string().required('Obligatorio'),
    gearboxPanLeak: yup.string().required('Obligatorio'),
    frontcrankshaftSealLeak: yup.string().required('Obligatorio'),
    rearCrankshaftSealLeak: yup.string().required('Obligatorio'),
    camshaftSealLeak: yup.string().required('Obligatorio'),
    gearboxSealLeak: yup.string().required('Obligatorio'),
    steeringHosesLeak: yup.string().required('Obligatorio'),
    steeringPumpLeak: yup.string().required('Obligatorio'),
    transferCaseLeak: yup.string().required('Obligatorio'),
    differentialLeak: yup.string().required('Obligatorio'),
    UpperRadiatorTankLeak: yup.string().required('Obligatorio'),
    LowerRadiatorTankLeak: yup.string().required('Obligatorio'),
    // required fields
    serviceDate: yup.string().required('Obligatorio'),
    fuelPumpHealth: yup.string().required('Obligatorio'),
  })
}
