import { filterParams } from '../assets/helpers/funciones'
import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const caseService = {
  getCasesByOperator: async (userId, params) => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      method: 'GET',
    }

    const response = await authInstance
      .get(`${url}/cases/?` + new URLSearchParams(filterParams(params)))
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getCaseById: async (caseId) => {
    const response = await authInstance
      .get(`${url}/cases/${caseId}`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  updateCaseTracking: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/addTracking`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  changeSolved: async (caseId, status) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/changeSolved/${status}`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  cancelCase: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/cancel`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  rollbackStage: async (caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/rollbackStage`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  failCase: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/failed`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  createExpressCheckupCase: async (data) => {
    const response = await authInstance
      .post(`${url}/cases/createExpressCheckupCase`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  finishCase: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/finish`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  updateProviderFailureReason: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/updateProviderFailureReason`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  createCase: async (data) => {
    const response = await authInstance
      .post(`${url}/cases/create`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
  editCase: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/update`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  updateCaseStage: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/updateStage`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
  scheduleCase: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/schedule`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  changeOperator: async (data, caseId) => {
    const response = await authInstance
      .put(`${url}/cases/${caseId}/updateUser`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getExportables: async (params) => {
    for (const key in params) {
      if (params[key] === undefined || params[key] === '') {
        delete params[key]
      }
    }

    const response = await authInstance
      .get(`${url}/cases/exportables?` + new URLSearchParams(params))
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getExpressCheckupCases: async (params) => {
    for (const key in params) {
      if (params[key] === undefined || params[key] === '') {
        delete params[key]
      }
    }

    const response = await authInstance
      .get(`${url}/cases/getExpressCheckupCases?` + new URLSearchParams(params))
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getExportablesAdmin: async (params) => {
    for (const key in params) {
      if (params[key] === undefined || params[key] === '') {
        delete params[key]
      }
    }

    const response = await authInstance
      .get(`${url}/cases/exportableAdmin?` + new URLSearchParams(params))
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getActiveScheduledCases: async (params) => {
    for (const key in params) {
      if (params[key] === undefined || params[key] === '') {
        delete params[key]
      }
    }

    const response = await authInstance
      .get(
        `${url}/cases/activeScheduledCasesList?` + new URLSearchParams(params)
      )
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
